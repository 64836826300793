import { useEffect, useState } from "react";
import Script from "next/script";
import Head from "next/head";

import "../styles/_global.scss";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const AppHeader = dynamic(() => import("../components/app-header"));
const Modal = dynamic(() => import("../components/modal"));
const pathnames = ["/", "/categories", "/all-stories"];

const App = ({ Component, pageProps }) => {
	const [isModalClose, setIsModalClose] = useState(false);
	const [isDark, setIsDark] = useState(true);
	const router = useRouter();

	useEffect(() => {
		const isDarkTheme = localStorage.getItem("isDark");
		if (isDarkTheme === "false") {
			setIsDark(false);
		}
	}, []);

	useEffect(() => {
		const { documentElement } = document;
		if (isDark) {
			documentElement.classList.add("dark-theme");
		} else {
			documentElement.classList.remove("dark-theme");
		}
	}, [isDark]);

	const handleSiteCheck = () => {
		setIsDark(!isDark);
		localStorage.setItem("isDark", `${!isDark}`);
	};

	// useEffect(() => {
	//   let isScrolling;
	//   window.addEventListener("scroll", () => {
	//     const flowers = document.querySelectorAll('.flower')
	//     flowers.forEach(item => item.classList.add('flower_moves'))
	//     clearTimeout(isScrolling);
	//     isScrolling = setTimeout(() => {
	//       flowers.forEach(item => item.classList.remove('flower_moves'))
	//     }, 1000);
	//   },false)
	// })

	return (
		<>
			<Head>
				<script>window.yaContextCb=window.yaContextCb||[]</script>
				<script
					src="https://yandex.ru/ads/system/context.js"
					async
				></script>
			</Head>

			{pathnames.includes(router.pathname) && isDark && (
				<>
					<div className="star"></div>

					<div className="star  star--2"></div>
				</>
			)}

			<div className="page_container">
				<AppHeader
					onSupport={() => {
						setIsModalClose(true);
					}}
					isDark={isDark}
					setIsDark={handleSiteCheck}
				/>

				<div className="component_container">
					<Component {...pageProps} />
				</div>
				{router.pathname === "/" && isDark && (
					<div className="man_with_baterfly"></div>
				)}
				{router.pathname === "/" && isDark && (
					<div className="man_baloon"></div>
				)}
			</div>

			{router.pathname !== "/" && isDark && (
				<div className="start_back"></div>
			)}

			<div className="y-adds-banner">
				<div id="yandex_rtb_R-A-2312269-1"></div>
				<Script id="yandex-ads -1" strategy="afterInteractive">
					{`
              window.yaContextCb.push(()=>{
                Ya.Context.AdvManager.render({
                  "blockId": "R-A-2312269-1",
                  "renderTo": "yandex_rtb_R-A-2312269-1"
                })
              })
            `}
				</Script>
			</div>

			<div className="y-adds-banner">
				<div id="yandex_rtb_R-A-2312269-3"></div>
				<Script id="yandex-ads-3" strategy="lazyOnload">
					{`
              window.yaContextCb.push(()=>{
				Ya.Context.AdvManager.render({
					"blockId": "R-A-2312269-3",
					"renderTo": "yandex_rtb_R-A-2312269-3"
				})
			})
            `}
				</Script>
			</div>

			<div className="y-adds-banner">
				<Script id="yandex-ads-flooradd" strategy="lazyOnload">
					{`
              window.yaContextCb.push(()=>{
				Ya.Context.AdvManager.render({
					"blockId": "R-A-2312269-4",
					"type": "floorAd",
					"platform": "touch"
				})
			})
            `}
				</Script>
			</div>

			{isModalClose && (
				<Modal onClose={() => setIsModalClose(false)}>
					<p className="support_links">
						<a
							className="support_link"
							target="_blank"
							rel="noreferrer nofollow"
							href="https://boosty.to/alina_avelis"
						>
							Boosty
						</a>
						<a
							className="support_link"
							target="_blank"
							rel="noreferrer nofollow"
							href="https://www.patreon.com/AlinaAvelis"
						>
							Patreon
						</a>
					</p>
				</Modal>
			)}
		</>
	);
};

export default App;
